/**
 * Public-facing javascript pack for Bootstrap 4.x (a/k/a Voyager)
 */
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "../javascripts/bootstrap_custom";
import { stimulusApplication } from "../javascripts/manage";

const publicContext = require.context(
  "../javascripts/controllers/public",
  true,
  /\.js$/
);
stimulusApplication.load(definitionsFromContext(publicContext));

const sharedContext = require.context(
  "../javascripts/controllers/shared",
  true,
  /\.js$/
);
stimulusApplication.load(definitionsFromContext(sharedContext));
